@import '../../styles/variables';

.container {
	display: grid;
	align-items: start;
	justify-items: start;

	/* these two lines ensure that there is a minimum of shifting of elements when
	swapping between this "Selected" component and the "Selector" component */
	align-content: start;
	min-height: 4.1875rem;
}

.row {
	display: grid;
	align-items: center;
	grid-template-columns: auto fit-content( 1.5rem );
	gap: 0.5rem;
}

.filename {
	font-weight: bold;
}

.icon {
	height: 1.5rem;
	width: 1.5rem;

	svg {
		fill: $red;
		height: 0.75rem;
		width: 0.75rem;
	}
}

.label {
	color: $darkBlue;
}
