/* stylelint-disable */
// Typefaces -- To remain at top level
@font-face {
	font-family: 'Brandon Grotesque';
	src: url(../../public/brandongrotesque-regular-webfont.woff2) format('woff2'),
		url(../../public/brandongrotesque-regular-webfont.woff) format('woff');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Brandon Grotesque';
	src: url(../../public/Brandon_bld.otf) format('opentype');
	font-weight: 700;
	font-style: bold;
}

// Global -- Caution when changing order
@import '_variables.scss';
@import '_mixins.scss';
@import '_classes.scss';

// Elements - Order agnostic
@import '_element-main.scss';

// Blocks - Order agnostic
@import '_block-back-button.scss';
@import '_block-move-file-modal.scss';

// Page - Order agnostic
@import '_page-contract-edit.scss';
@import '_page-contract-template.scss';
@import '_page-contract-templates.scss';
@import '_page-contract-view.scss';
@import '_page-contracts.scss';
@import '_page-dashboard.scss';
@import '_page-document.scss';
@import '_page-error.scss';
@import '_page-invoice-edit.scss';
@import '_page-invoices.scss';
@import '_page-members.scss';
@import '_page-org-member-signup.scss';
@import '_page-org-signup.scss';
@import '_page-org.scss';
@import '_page-proposals.scss';
@import '_page-reset-password.scss';
@import '_page-resource.scss';
@import '_page-signup-choose-user-type.scss';
@import '_page-user.scss';
@import '_page-vendors.scss';
@import '_tooltip.scss';

// Page Groups
@import '_page-folders.scss';
@import '_page-client-folders.scss';
@import '_page-company-folders.scss';

// Global
* {
	font-family: 'Brandon Grotesque', sans-serif;
	box-sizing: border-box;
}

html,
body {
	max-width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	overflow-x: hidden;
	position: relative;
}

html {
	body {
		overscroll-behavior-y: contain;

		&.mobile-contract-signing-orientation-landscape {
			@media (max-height: 400px) {
				width: 100vw;
				height: 100vh;
				overflow: hidden;

				&::before {
					content: '';
					width: 100%;
					height: 100%;
					position: fixed;
					top: 0;
					left: 0;
					background-color: rgba(255, 255, 255, 0.7);
					z-index: 98;
				}

				&::after {
					content: 'Rotate your device to portrait mode to continue';
					width: calc(100% - 2rem);
					position: fixed;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					transform-origin: center center;
					font-size: 2.25rem;
					z-index: 99;
					color: $darkBlue;
					text-align: center;
				}

				.x-hellosign-embedded {
					width: 100vh;
					height: 100vw;
					top: 0;
					left: 100%;
					transform: rotate(90deg);
					transform-origin: left top;
				}
			}
		}

		.x-hellosign-embedded {
			z-index: 25;
		}

		.intercom-launcher {
			transition: 250ms linear 200ms, opacity 80ms linear 280ms !important;
		}
	}
}

#__next-build-watcher {
	display: none;
	pointer-events: none;
}
